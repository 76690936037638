import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UserService } from '@mei/common/core/services/user.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';

/** Root component. */
@Component({
	selector: 'meiw-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet, AsyncPipe, MatProgressBarModule],
})
export class AppComponent {
	private readonly userService = inject(UserService);

	/** Whether user is fetching. */
	protected readonly isFetchingUser$ = this.userService.isFetchingUser$;
}
